import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'view.answerQuotation.title',
    defaultMessage: 'Quotation validation',
  },

  descriptionLabel: {
    id: 'view.modal.quote.form.description.label',
    defaultMessage: 'Description',
  },
  service: {
    id: 'components.myaccount.quoteInformation.service',
    defaultMessage: 'Service',
  },
  recipient: {
    id: 'view.quotation.recipient.label',
    defaultMessage: 'Recipient',
  },
  optionalSpareParts: {
    id: 'view.quotation.optionalSpareParts.label',
    defaultMessage: 'Optional spare parts',
  },
  requiredSpareParts: {
    id: 'view.quotation.requiredSpareParts.label',
    defaultMessage: 'Required spare parts',
  },
  total: {
    id: 'view.quotation.total.label',
    defaultMessage: 'Total TTC',
  },
  statusSpareParts: {
    id: 'view.quotation.status.label',
    defaultMessage: 'Status',
  },
  statusSparePartsMobileView: {
    id: 'view.quotation.status.label.mobileView',
    defaultMessage: 'Status',
  },
  sparePartConditionNoMoreAvailable: {
    id: 'view.issueAQuotation.sparepartQuotationLines.sparePartConditionNoMoreAvailable.errorMessage',
    defaultMessage: 'Selected spare part is not available',
  },
  buttonCancel: {
    id: 'view.component.confirmModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  buttonValidate: {
    id: 'view.quotation.buttonValidate',
    defaultMessage: 'Validate',
  },
  buttonReject: {
    id: 'view.quotation.buttonReject',
    defaultMessage: 'Reject',
  },
  answerQuotationRecommendUsedPartMessage: {
    id: 'view.answerAQuotation.recommendUsedPartMessage',
    defaultMessage:
      'Make a gesture for the environment by choosing a spare part from the circular economy',
  },
  congratulation: {
    id: 'view.quotationTab.bonus.congratulation',
    defaultMessage: 'Congratulation',
  },
  bonusDidactic: {
    id: 'view.quotationTab.bonus.didactic',
    defaultMessage: 'You have all requirements to get bonus',
  },
  learnMore: {
    id: 'view.quotationTab.bonus.learnMore',
    defaultMessage: 'Learn more',
  },
});
