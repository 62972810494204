import React, { FC, Suspense } from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { formatStripeAmount } from '@savgroup-front-common/core/src/formatters/intl';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { ClosedLockUnfilled } from '@savgroup-front-common/core/src/protons/icons';
import { PAYMENT_STATUS } from '@savgroup-front-common/types';

import { $PaymentContainer } from '../PaymentStripe/components/CheckoutForm.styles';

import {
  $ButtonLink,
  $Heading,
  $PaymentLabel,
} from './CashRegisterPayment.styles';
import { PaymentStatus } from './CashRegisterPayment.types';
import messages from './messages';

interface CashRegisterPaymentProps {
  amount?: number;
  currency?: CURRENCIES;
  isFormCompleted: boolean;
  paymentStatus?: PaymentStatus;
}

const CashRegisterPayment: FC<CashRegisterPaymentProps> = ({
  amount = 0,
  currency = CURRENCIES.EUR,
  isFormCompleted,
  paymentStatus,
}) => {
  return (
    <Suspense fallback={<BaseLoader />}>
      <$PaymentContainer>
        <$Heading>
          <SafeFormattedMessageWithoutSpread
            message={messages.paymentWithoutStripeTitle}
          />
        </$Heading>
        {(!paymentStatus ||
          paymentStatus.status !== PAYMENT_STATUS.CREATED) && (
          <$PaymentLabel>
            <SafeFormattedMessageWithoutSpread
              message={messages.noPaymentFound}
            />
          </$PaymentLabel>
        )}
        {paymentStatus?.status === PAYMENT_STATUS.CREATED && (
          <>
            <$PaymentLabel>
              <SafeFormattedMessageWithoutSpread
                message={messages.paymentWithoutStripeDidactic}
              />
            </$PaymentLabel>
            <$ButtonLink
              isExternal
              href={paymentStatus.paymentUrlWithExternalId}
              primary
              icon={<ClosedLockUnfilled />}
              disabled={!isFormCompleted}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.paymentWithoutStripeButton}
                values={{
                  amount: formatStripeAmount(amount, currency),
                }}
              />
            </$ButtonLink>
          </>
        )}
      </$PaymentContainer>
    </Suspense>
  );
};

export default CashRegisterPayment;
