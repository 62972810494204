import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTitle } from 'react-use';

import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { retryImportChunk } from '@savgroup-front-common/core/src/helpers/retryImportChunk';
import { AppErrorBoundary } from '@savgroup-front-common/core/src/molecules/ErrorBoundary';
import { AuthConfiguration } from 'myaccount/configuration';

import { RedirectWithoutNewDesign } from '../../components/RedirectWithoutNewDesign';
import FileDetailPage from '../../newDesignPages/FileFollowupsPage/FileFollowupsPage';
import HomePage from '../../newDesignPages/HomePage/HomePage';
import { IrshPages } from '../../newDesignPages/irshPages/IrshPages';
import { OrdersPage } from '../../newDesignPages/OrdersPage';
import DebugLayout from '../DebugLayout';
import { FINAL_ROUTES, SERVICE_PORTAL_HOME_ROUTES } from '../routes/Routes';

import Header from './header/Header';
import { InitContext } from './InitProvider/InitProvider.context';
import messages from './messages';
import { $PageWrapper } from './NewLayout.styles';

const ClientVisioPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/ClientVisioPage/ClientVisioPage')),
);

interface ClassiqueLayoutProps {
  sellerId: string;
}

const ClassiqueLayout: React.FC<ClassiqueLayoutProps> = ({ sellerId }) => {
  const { enableServicePortal } = useContext(InitContext);

  useTitle(safeFormattedIntlString(messages.selfcareTitle));

  return (
    <AppErrorBoundary>
      <$PageWrapper>
        <NeedHelp clientId={AuthConfiguration.clientId} />
        <Header sellerId={sellerId} enableServicePortal={enableServicePortal} />

        <Routes>
          <Route element={<DebugLayout />}>
            <Route
              path={'/new_design/*'}
              element={<RedirectWithoutNewDesign />}
            />

            {!enableServicePortal && (
              <>
                <Route index element={<OrdersPage />} />
                <Route path={FINAL_ROUTES.PRODUCTS} element={<OrdersPage />} />
                <Route
                  path={FINAL_ROUTES.ORDER_CREATE_CLAIM}
                  element={<OrdersPage />}
                />

                <Route path="/*" element={<IrshPages />} />

                <Route
                  path={FINAL_ROUTES.VIDEO_CALL}
                  element={<ClientVisioPage />}
                />
                <Route
                  path={FINAL_ROUTES.FILE_FOLLOWUPS}
                  element={<FileDetailPage />}
                />

                <Route
                  path="/*"
                  element={<Navigate to={FINAL_ROUTES.HOME} />}
                />
              </>
            )}

            {enableServicePortal && (
              <>
                <Route index element={<HomePage />} />

                <Route
                  path="/*"
                  element={
                    <Navigate to={SERVICE_PORTAL_HOME_ROUTES.PRODUCTS} />
                  }
                />
              </>
            )}
          </Route>
        </Routes>
      </$PageWrapper>
    </AppErrorBoundary>
  );
};

export default ClassiqueLayout;
